import { createGlobalStyle } from 'styled-components'

export const CustomGlobalCss = createGlobalStyle`
  html {
    height: 100%;
    font-size: 62.5%;
    background-color: ${({ theme }) => theme.colors.bg_white};
  }
  
  body {
    height: 100%;
    font-family: ${({ theme }) => theme.font.family.sanSerif};
  }
  
  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }
`
