import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from 'styled-components'

import { theme } from './src/styles/theme'
import { CssReset } from './src/styles/cssReset'
import { CustomGlobalCss } from './src/styles/customGlobalCss'
import i18n from './src/providers/i18n'

// Set up any Provider components that will wrap the application.
export const rootWrapper = ({ element }) => (
  <>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </I18nextProvider>
  </>
)

// Set wrapper components around pages that won’t get unmounted on page changes.
export const pageWrapper = ({ element }) => (
  <>
    <CssReset />
    <CustomGlobalCss />
    {element}
  </>
)
