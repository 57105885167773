import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  resources: {
    en: {
      translation: require('/static/locales/en/translation.json')
    },
    ja: {
      translation: require('/static/locales/ja/translation.json')
    }
  },
  debug: process.env.NODE_ENV === "development",
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})
  .catch(() => console.log('error on i18n'))

export default i18n
