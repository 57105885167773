import { Theme } from '../types/theme'

export const theme = (): Theme => {
  const _theme = {
    content: {
      width: '1060px',
      boxPadding: '60px',
    },
    breakpoints: {
      small: '560px',
      medium: '740px'
    },
    font: {
      family: {
        sanSerif: `'Roboto', 'Helvetica', 'Hiragino Sans', 'Meiryo', sans-serif`,
        serif: `'Georgia', 'Hiragino Mincho ProN', '游明朝', serif`,
      },
      size: {
        rem3_2: '3.2rem',
        rem2_5: '2.5rem',
        rem2_2: '2.2rem',
        rem2: '2rem',
        rem1_8: '1.8rem',
        rem1_6: '1.6rem',
        rem1_4: '1.4rem',
        rem1_3: '1.3rem',
        rem1_2: '1.2rem',
      },
      weight: {
        w300: '300',
        w400: '400',
        w500: '500',
        w700: '700',
      },
    },
    colors: {
      bg_black: '#1b1b1b',
      bg_skill: '#222222',
      bg_tag: '#2e2e2e',
      bg_white: '#f6f6f6',
      bg_input: '#fafafa',

      typ_black: '#262626',
      typ_darkGrey: '#535353',
      typ_grey: '#999999',
      typ_lightGrey: '#d0d0d0',
      typ_offWhite: '#f3f3f3',
      typ_white: '#ffffff',
      typ_purple: '#A421AF',
    },
  }

  const typ = {
    logo: `
      font-size: clamp(2rem, 5vw, ${_theme.font.size.rem3_2});
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_black};
    `,
    langSwitch: `
      font-size: ${_theme.font.size.rem1_4};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_grey};
    `,

    techStackTitle: `
      font-size: ${_theme.font.size.rem3_2};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_offWhite};
      line-height: 4rem;
    `,
    techStackDesc: `
      font-size: ${_theme.font.size.rem1_8};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_offWhite};
    `,
    techStackMethodTitle: `
      font-size: ${_theme.font.size.rem2_2};
      font-weight: ${_theme.font.weight.w500};
      color: ${_theme.colors.typ_offWhite};
    `,
    techStackMethodDesc: `
      font-size: ${_theme.font.size.rem1_4};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_grey};
      line-height: 1.7rem;
    `,
    techStackSkillName: `
      font-size: ${_theme.font.size.rem1_4};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_lightGrey};
    `,

    worksSectionTitle: `
      font-size: ${_theme.font.size.rem1_4};
      font-weight: ${_theme.font.weight.w700};
      color: ${_theme.colors.typ_darkGrey};
    `,
    workTitle: `
      font-size: ${_theme.font.size.rem3_2};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_black};
    `,
    workDesc: `
      font-size: ${_theme.font.size.rem1_6};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_darkGrey};
      line-height: 1.9rem;
    `,
    workUrl: `
      font-size: ${_theme.font.size.rem1_3};
      font-weight: ${_theme.font.weight.w500};
      color: ${_theme.colors.typ_purple};
      letter-spacing: 0.1rem;
    `,
    workTag: `
      font-size: ${_theme.font.size.rem1_2};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_white};
      letter-spacing: 0.05rem;
    `,
    workDetailButton: `
      font-size: ${_theme.font.size.rem1_6};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_black};
    `,

    profileMyName: `
      font-size: ${_theme.font.size.rem2};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_white};
    `,
    profileTitle: `
      font-size: ${_theme.font.size.rem2_5};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_white};
    `,
    profileDesc: `
      font-size: ${_theme.font.size.rem1_8};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_offWhite};
    `,
    profileSubDesc: `
      font-size: ${_theme.font.size.rem1_4};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_grey};
    `,
    resumeDate: `
      font-size: ${_theme.font.size.rem1_4};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_purple};
    `,
    resumeTitle: `
      font-size: ${_theme.font.size.rem2};
      font-weight: ${_theme.font.weight.w500};
      color: ${_theme.colors.typ_offWhite};
      letter-spacing: 0.1rem;
    `,

    contactTitle: `
      font-size: ${_theme.font.size.rem2_5};
      font-weight: ${_theme.font.weight.w400};
      color: ${_theme.colors.typ_black};
    `,
    contactDesc: `
      font-size: ${_theme.font.size.rem1_4};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_black};
    `,
    contactInput: `
      font-size: ${_theme.font.size.rem1_6};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_black};
      font-family: inherit;
    `,
    contactPlaceholder: `
      font-size: ${_theme.font.size.rem1_6};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_lightGrey};
    `,
    contactButton: `
      font-size: ${_theme.font.size.rem1_6};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_offWhite};
    `,

    footer: `
      font-size: ${_theme.font.size.rem1_6};
      font-weight: ${_theme.font.weight.w300};
      color: ${_theme.colors.typ_darkGrey};
    `
  }

  return {
    ..._theme,
    typ,
  }
}
